<template>
  <div>
    <div
      v-if="!isOpen && greetingOpen && styles.greeting.is_active"
      :class="
        styles.greeting.position === 'top'
          ? 'qchat-box__top'
          : 'qchat-box__left'
      "
    >
      <div class="qchat-box__box">
        <p class="qchat-box__text">{{ styles.greeting.greetingMsg }}</p>
        <div
          class="qchat-box__close d-flex qcentered-item"
          @click="toggleGreeting"
        >
          <Icons type="la-times" size="10" class="qchat-box__close-icon" />
        </div>
      </div>
    </div>
    <div
      v-if="!isOpen"
      class="qchat-button"
      :style="styles.floatingButton"
      @click="toggleChat"
    >
      <Icons
        v-if="isOpen"
        class="qchat-icon__close"
        :class="style.buttonIcon"
        type="la-times"
        size="32"
      />
      <div v-else class="qchat-icon__chat qcentered-item">
        <img v-if="styles.logo.is_active" :src="styles.logo.url" />
        <Icons
          v-else
          :fill="styles.floatingButton.color"
          type="la-sms"
          size="32"
        />
      </div>
      <div
        v-show="style.withTail"
        class="qchat-button__dot"
        :style="{
          'background-color': styles.floatingButton['background-color']
        }"
      ></div>
    </div>
    <Messenger v-if="isOpen" @close="toggleChat" />
  </div>
</template>
<script>
import { mapState } from "vuex";
const Messenger = () => import("./messenger/Panel.vue");
const Icons = () => import("./Icons/index.vue");

export default {
  components: { Messenger, Icons },
  data() {
    return {
      isOpen: false,
      greetingOpen: true,
      style: {
        buttonIcon: "la-times",
        withTail: true
      }
    };
  },
  mounted() {
    if (this.styles.greeting.is_active) {
      window.parent.postMessage(
        {
          eventName: "setFrameSize",
          data: { height: " 160px", width: "410px" }
        },
        "*"
      );
    }
  },
  computed: {
    ...mapState("messenger", ["styles"])
  },
  methods: {
    toggleChat() {
      this.isOpen = !this.isOpen;
      this.greetingOpen = true;
      if (this.isOpen) {
        let height = "590px";
        let width = "380px";
        const screenWidth =
          window === window.parent
            ? window.innerWidth
            : this.getParameterByName("w");
        const screenHeight =
          window === window.parent
            ? window.innerHeight
            : this.getParameterByName("h");
        const wrapper = document.getElementById("qontak-webchat");
        if (wrapper) {
          if (window === window.parent) {
            wrapper.style.height = "100vh";
          }
          if (screenWidth <= 414) {
            width = screenWidth;
            height = screenHeight;
            const className =
              window.outerWidth <= 320
                ? "qchat__full-screen-sm"
                : "qchat__full-screen";
            wrapper.setAttribute("class", className);
          }
        }

        window.parent.postMessage(
          {
            eventName: "setFrameSize",
            data: { height, width }
          },
          "*"
        );
        window.parent.postMessage(
          {
            eventName: "onPanelTrigger",
            data: { isOpened: this.isOpen }
          },
          "*"
        );
      } else {
        window.parent.postMessage(
          {
            eventName: "setFrameSize",
            data: { height: "94px", width: "94px" }
          },
          "*"
        );
      }
      this.$emit("onClicked");
    },
    toggleGreeting() {
      this.greetingOpen = false;
      window.parent.postMessage(
        {
          eventName: "setFrameSize",
          data: { height: "94px", width: "94px" }
        },
        "*"
      );
    }
  }
};
</script>
